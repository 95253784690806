.waitinglist-container {
  background-color: #141425;
  background-image: url("./ilustrator\ 1.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 6rem 4rem;

  &__top {
    align-self: center;

    &__heading {
      &__text {
        font-weight: 700;
        font-size: 1.8rem;
      }
      &__bar {
        width: 120px;
        height: 10px;
        margin-top: 0.7rem;
        background-color: #7c3dfc;
      }
    }
    &__socials {
      margin-top: 1.5rem;
      display: flex;
      gap: 1rem;
    }
    &__body-text {
      color: #9ba5b2;
      font-size: 1.6rem;
      margin-top: 1.8rem;
      width: 80%;
    }

    &__form {
      margin-top: 1rem;

      input[type="number"] {
        -moz-appearance: textfield;
      }

      input {
        background-color: #1d1d30;
        padding: 1.2rem 2rem;
        width: 370px;
        outline: none;
        border: 2px solid rgba(158, 166, 179, 0.17);
        border-radius: 10px;
        font-size: 1rem;
        color: #fff;

        &::placeholder {
          color: #9ba5b2;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      &__submit {
        display: flex;
        justify-content: space-between;
        background: linear-gradient(87.75deg, #7c3dfc 1.89%, #8c5eea 96.74%);
        border-radius: 5px;
        width: 110px;
        padding: 0.7rem 2rem;
        font-size: 1.1rem;
        margin-top: 2rem;
      }
    }
  }

  &__bottom {
    display: block;
    width: 450px;
    align-self: center;
  }

  @media screen and (min-width: 1700px) {
    justify-content: space-evenly;

    &__bottom {
      width: 600px;
    }
  }

  @media screen and (max-width: 1100px) {
    &__top {
      &__heading {
        &__text {
          font-size: 1.5rem;
        }
      }
      &__socials {
        gap: 0.5rem;
        &__icons {
          width: 40px;
        }
      }
      &__body-text {
        font-size: 1.2em;
      }
      &__form {
        input {
          width: 280px;
          padding: 1rem 1.8rem;
        }
      }
    }
    &__bottom {
      width: 340px;
    }
  }

  @media screen and (max-width: 880px) {
    padding-inline: 2rem;
    input {
      width: 75%;
      padding: 1.2rem 1rem;
    }

    &__top {
      align-self: flex-start;

      &__body-text {
        width: 100%;
      }
    }

    flex-direction: column;
    &__bottom {
      margin-top: 7rem;
      width: 380px;
    }
  }

  @media screen and (max-width: 720px) {
    padding: 6rem 1.5rem;
  }
  @media screen and (max-width: 425px) {
    &__top {
      &__form {
        &__submit {
          margin-top: 1.5rem;
        }
      }
    }
    input {
      width: 290px;
    }

    &__bottom {
      width: 340px;
    }
  }

  @media screen and (max-width: 375px) {
    padding-top: 1rem;
    &__top {
      &__body-text {
        font-size: 1.1rem;
        width: 95%;
      }

      &__socials {
        gap: 0.5rem;
        &__icons {
          width: 100%;
        }
      }
      &__form {
        &__submit {
          margin-top: 1rem;
        }
      }
    }
    input {
      width: 80%;
      padding: 1rem 1rem;
    }
    &__bottom {
      width: 100%;
    }
  }
}
