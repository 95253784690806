.hero-container {
  padding: 2rem 4rem;
  background: url("./heroBg.png");
  background-size: cover;
  color: #fff;

  &__heading {
    margin: 5rem 0;
    text-align: center;

    &__title {
      font-size: 3rem;
    }

    &__text {
      color: #ccd4df;
      font-size: 1.7rem;
      width: 85%;
      line-height: 1.5;
      margin: 0 auto;
      margin-top: 2rem;
    }

    &__launch {
      background-color: #1c1c2f;
      font-size: 2rem;
      color: #fff;
      font-weight: 700;
      margin: 0 auto;
      margin-top: 6rem;
      padding: 1.8rem;
      width: 40vw;
      border-radius: 20px;
    }
  }

  @media screen and (max-width: 1024px) {
    &__heading {
      &__title {
        font-size: 2rem;
      }
      &__text {
        font-size: 1.3rem;
      }
      &__launch {
        font-size: 1.6rem;
        color: #fff;
      }
    }
  }

  @media screen and (max-width: 880px) {
    padding: 2rem 2rem;
    &__heading {
      &__text {
        color: #ccd4df;
        font-size: 1.3rem;
        width: 85%;
        line-height: 1.5;
        margin: 0 auto;
        margin-top: 2rem;
      }
      &__launch {
        width: 60vw;
      }
    }
  }

  @media screen and (max-width: 375px) {
    padding: 2rem 1.5rem;
    &__heading {
      &__text {
        width: 90%;
        font-size: 1.1rem;
      }

      &__launch {
        width: 65vw;
        font-size: 1.2rem;
        padding: 1.2rem;
        border-radius: 15px;
      }
    }
  }
}
