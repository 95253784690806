.hugerewards-container {
  color: #fff;
  padding: 6rem 4rem;

  &__steps {
    margin-top: 3rem;
    display: flex;
    gap: 2rem 1.4rem;
    flex-wrap: wrap;
    justify-content: space-between;

    &__step {
      height: 300px;
      background-color: #1d1d30;
      padding: 2rem;
      border-radius: 24px;
      width: 400px;
      flex-grow: 1;

      &__title-icon {
        transform: translateX(-1.4rem);
      }
      &__title {
        font-size: 1.6rem;
      }

      &__body {
        width: 80%;
        line-height: 1.6rem;
        color: #9ba5b2;
        margin-top: 1rem;
      }
    }
  }



  @media screen and (max-width: 880px) {
    padding: 6rem 2rem;
    &__steps {
      &__step {
        border-radius: 20px;
        width: 200px;
        padding-bottom: 3.5rem;
      }
    }
  }


  @media screen and (max-width: 1200px) {
    &__steps {
      &__step {
        width: 280px;
      }
    }
  }

  @media screen and (max-width: 775px) {
    &__steps {
      &__step {
        width: 180px;
      }
    }
  }


  @media screen and (max-width: 720px) {
    padding: 6rem 1.5rem;
    &__steps {
      &__step {
        padding-bottom: 5rem;
      }
    }
  }
}
