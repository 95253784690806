.footer-container {
  background: #121620;
  position: relative;
  color: #fff;
  margin: 0 auto;
  padding: 3rem 4rem 1rem;

  &__socials {
    width: fit-content;
    margin: 0 auto;
    padding-top: 8rem;

    &__set {
      width: 612px;
      &__icon {
        width: 60px;
      }
    }
  }

  &__text {
    width: fit-content;
    margin: 0 auto;
    padding: 3rem 0 2.5rem;
    text-align: center;
    color: #ccd4df;
  }

  @media screen and (max-width: 535px) {
    &__socials {
      &__set {
        width: 100% !important;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 3rem 1rem 1rem;

    &__socials {
      &__set {
        width: 500px;

        &__icon {
          width: 50px;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    &__socials {
      &__set {
        margin: 0 auto;
      }
    }
  }
}
