.join-container {
  background-color: #1d1d30;

  padding: 10rem 4rem;

  &__text {
    margin: 0 auto;
    font-size: 1.6rem;
    line-height: 2.7rem;
    color: #ccd4df;
    text-align: center;
    width: 90%;
  }

  &__button {
    display: block;
    width: fit-content;
    text-decoration: none;
    margin: 0 auto;
    margin-top: 3rem;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    background: #7c3dfc;

    color: #fff;
    border-radius: 4px;
  }

  @media screen and (min-width: 1440px){
    &__text {
      width: 60%;
      font-size: 1.6rem;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 7rem 2rem;
    &__text {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }
  }
  @media screen and (max-width: 550px) {
    &__text {
      width: 100%;
      font-size: 1.2rem;
    }
  }

  @media screen and (max-width: 375px) {
    &__text {
      width: 100%;
      font-size: 1.2rem;
    }

    &__button {
      padding: 1rem 1.8rem;
      font-size: 1rem;
    }
  }
}
