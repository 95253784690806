.nav-container {
  &__logo {
    width: 230px;
  }

  @media screen and (max-width: 1024px){
    &__logo{
      width: 180px;
    }
    
  }

  @media screen and (max-width: 375px){
    &__logo{
      width: 150px;
    }
    
  }
}
